<template>
  <div>
    <div class="introduction">
      <svg
        id="introAnime"
        viewBox="0 0 1024 500"
        preserveAspectRatio="xMidYMid meet"
      >
        <g id="logo-MW">
          <path
            id="mw1"
            d="M479.209,225.498l-65.488,-196.44c-39.002,0.003 -73.846,25.125 -86.171,62.128l-44.78,134.312c39.009,0 73.862,-25.121 86.198,-62.128l12.035,-36.079l32.718,98.207l65.488,0Z"
            pathLength="1"
          />
          <path
            id="mw2"
            d="M577.443,225.498l-44.78,-134.312c-12.328,-37.012 -47.187,-62.136 -86.198,-62.128l44.78,134.312c12.328,37.012 47.186,62.136 86.198,62.128Z"
            pathLength="1"
          />
          <path
            id="mw3"
            d="M654.967,91.186l-12.035,36.105l-32.745,-98.233l-65.489,0l65.489,196.44c39.002,-0.003 73.846,-25.125 86.171,-62.128l44.78,-134.312c-39.002,0.003 -73.846,25.125 -86.171,62.128Z"
            pathLength="1"
          />
        </g>
        <!-- ---------------------------------------------------------- -->
        <!-- morgan wilson -->
        <!-- ---------------------------------------------------------- -->
        <g id="mo-00">
          <!-- m -->
          <path
            d="M256.867,361.73l0,-30.972c0,-4.912 3.988,-8.9 8.9,-8.9c4.912,0 8.9,3.988 8.9,8.9l0,30.972l0,-30.972c0,-4.912 3.988,-8.9 8.9,-8.9c4.912,0 8.9,3.988 8.9,8.9l0,30.972"
            pathLength="1"
          />
          <!-- o -->
          <path
            d="M323.617,357.458c-9.824,0 -17.8,-7.976 -17.8,-17.8c0,-9.824 7.976,-17.8 17.8,-17.8c9.824,0 17.8,7.976 17.8,17.8c0,9.824 -7.976,17.8 -17.8,17.8"
            pathLength="1"
          />
          <!-- r -->
          <path
            d="M354.767,361.908l0,-31.15c0,-4.912 3.988,-8.9 8.9,-8.9l4.45,0"
            pathLength="1"
          />
          <!-- g -->
          <path
            d="M399.267,357.458l-4.45,0c-9.824,0 -17.8,-7.976 -17.8,-17.8c0,-9.824 7.976,-17.8 17.8,-17.8c9.824,0 17.8,7.976 17.8,17.8l0,22.259c0,9.824 -7.976,17.8 -17.8,17.8l-4.45,-0.009"
            pathLength="1"
          />
          <!-- a -->
          <path
            d="M448.217,357.458l-4.45,0c-9.824,0 -17.8,-7.976 -17.8,-17.8c0,-9.824 7.976,-17.8 17.8,-17.8c9.824,0 17.8,7.976 17.8,17.8l0,22.25"
            pathLength="1"
          />
          <!-- n -->
          <path
            d="M474.917,361.908l0,-22.25c0,-9.824 7.976,-17.8 17.8,-17.8c9.824,0 17.8,7.976 17.8,17.8l0,22.25"
            pathLength="1"
          />
          <!-- w -->
          <path
            d="M541.667,317.408l15.022,36.342c1.616,2.245 4.252,3.708 7.228,3.708c4.912,0 8.9,-3.988 8.9,-8.9l0,-13.35l0,13.35c0,4.912 3.988,8.9 8.9,8.9c4.912,0 8.9,-3.988 8.9,-8.9l0,-31.15"
            pathLength="1"
          />
          <!-- i -->
          <path d="M603.967,317.408l0,44.5" pathLength="1" />
          <!-- l -->
          <path
            d="M617.317,304.058l0,48.95c0,2.456 1.994,4.45 4.45,4.45l11.866,0"
            pathLength="1"
          />
          <!-- s -->
          <path
            d="M638.083,357.458l4.45,0c7.368,0 13.35,-5.982 13.35,-13.35l0,-8.9c0,-7.368 5.982,-13.35 13.35,-13.35l4.45,0"
            pathLength="1"
          />
          <!-- o -->
          <path
            d="M700.383,357.458c-9.824,0 -17.8,-7.976 -17.8,-17.8c0,-9.824 7.976,-17.8 17.8,-17.8c9.824,0 17.8,7.976 17.8,17.8c0,9.824 -7.976,17.8 -17.8,17.8"
            pathLength="1"
          />
          <!-- n -->
          <path
            d="M731.533,361.908l0,-22.25c0,-9.824 7.976,-17.8 17.8,-17.8c9.824,0 17.8,7.976 17.8,17.8l0,22.25"
            pathLength="1"
          />
        </g>
        <!-- ---------------------------------------------------------- -->
        <!-- designer graphique -->
        <!-- ---------------------------------------------------------- -->
        <g id="dg-00">
          <!-- d -->
          <path
            class="Adg1"
            d="M168.25,410.125l-4.45,0c-9.824,0 -17.8,7.976 -17.8,17.8c0,9.824 7.976,17.8 17.8,17.8c9.824,0 17.8,-7.976 17.8,-17.8l0,-35.6"
            pathLength="1"
          />
          <!-- e -->
          <path
            class="Adg2"
            d="M226.1,436.727c-3.263,3.698 -8.036,6.031 -13.35,6.031c-9.824,0 -17.8,-7.975 -17.8,-17.8c0,-9.824 7.976,-17.8 17.8,-17.8c9.824,0 17.8,7.976 17.8,17.8l-26.7,0"
            pathLength="1"
          />
          <!-- s -->
          <path
            class="Adg3"
            d="M239.45,442.758l4.45,0c7.368,0 13.35,-5.981 13.35,-13.35l0,-8.9c0,-7.368 5.982,-13.35 13.35,-13.35l4.45,0"
            pathLength="1"
          />
          <!-- i -->
          <path class="Adg4" d="M283.95,402.708l0,44.5" pathLength="1" />
          <!-- g -->
          <path
            class="Adg5"
            d="M319.55,442.75l-4.45,0c-9.824,0 -17.8,-7.976 -17.8,-17.8c0,-9.824 7.976,-17.8 17.8,-17.8c9.824,0 17.8,7.976 17.8,17.8l0,22.258c0,9.825 -7.976,17.8 -17.8,17.8l-4.45,-0.008"
            pathLength="1"
          />
          <!-- n -->
          <path
            class="Adg6"
            d="M346.25,447.2l0,-22.25c0,-9.824 7.976,-17.8 17.8,-17.8c9.824,0 17.8,7.976 17.8,17.8l0,22.25"
            pathLength="1"
          />
          <!-- e -->
          <path
            class="Adg7"
            d="M426.35,436.719c-3.263,3.697 -8.036,6.031 -13.35,6.031c-9.824,0 -17.8,-7.976 -17.8,-17.8c0,-9.824 7.976,-17.8 17.8,-17.8c9.824,0 17.8,7.976 17.8,17.8l-26.7,0"
            pathLength="1"
          />
          <!-- r -->
          <path
            class="Adg8"
            d="M444.15,448.692l0,-31.15c0,-4.912 3.988,-8.9 8.9,-8.9l4.45,0"
            pathLength="1"
          />
          <!-- g -->
          <path
            class="Adg9"
            d="M535.35,444.242l-4.45,0c-9.824,0 -17.8,-7.976 -17.8,-17.8c0,-9.824 7.976,-17.8 17.8,-17.8c9.824,0 17.8,7.976 17.8,17.8l0,22.258c0,9.825 -7.976,17.8 -17.8,17.8l-4.45,-0.008"
            pathLength="1"
          />
          <!-- r -->
          <path
            class="Adg10"
            d="M562.05,448.692l0,-31.15c0,-4.912 3.988,-8.9 8.9,-8.9l4.45,0"
            pathLength="1"
          />
          <!-- a -->
          <path
            class="Adg11"
            d="M606.55,444.242l-4.45,0c-9.824,0 -17.8,-7.976 -17.8,-17.8c0,-9.824 7.976,-17.8 17.8,-17.8c9.824,0 17.8,7.976 17.8,17.8l0,22.25"
            pathLength="1"
          />
          <!-- p -->
          <path
            class="Adg12"
            d="M633.25,470.942l0,-44.5c0,-9.824 7.976,-17.8 17.8,-17.8c9.824,0 17.8,7.976 17.8,17.8c0,9.824 -7.976,17.8 -17.8,17.8l-4.45,0"
            pathLength="1"
          />
          <!-- h -->
          <path
            class="Adg13"
            d="M682.2,392.325l0,56.367l0,-22.25c0,-9.824 7.976,-17.8 17.8,-17.8c9.824,0 17.8,7.976 17.8,17.8l0,22.25"
            pathLength="1"
          />
          <!-- i -->
          <path class="Adg14" d="M731.15,448.692l0,-44.5" pathLength="1" />
          <!-- q -->
          <path
            class="Adg15"
            d="M766.75,444.242l-4.45,0c-9.824,0 -17.8,-7.976 -17.8,-17.8c0,-9.824 7.976,-17.8 17.8,-17.8c9.824,0 17.8,7.976 17.8,17.8l0,44.5"
            pathLength="1"
          />
          <!-- u -->
          <path
            class="Adg16"
            d="M793.45,404.192l0,22.25c0,9.824 7.976,17.8 17.8,17.8c9.824,0 17.8,-7.976 17.8,-17.8l0,-22.25"
            pathLength="1"
          />
          <!-- e -->
          <path
            class="Adg17"
            d="M873.55,438.211c-3.263,3.697 -8.036,6.031 -13.35,6.031c-9.824,0 -17.8,-7.976 -17.8,-17.8c0,-9.824 7.976,-17.8 17.8,-17.8c9.824,0 17.8,7.976 17.8,17.8l-26.7,0"
            pathLength="1"
          />
        </g>
        <!-- ---------------------------------------------------------- -->
        <!-- integrateur -->
        <!-- ---------------------------------------------------------- -->
        <g id="int-00">
          <!-- i -->
          <path class="Aint1" d="M315.4,449.475l0,-44.5" pathLength="1" />
          <!-- n -->
          <path
            class="Aint2"
            d="M329.45,449.475l0,-22.25c0,-9.824 7.976,-17.8 17.8,-17.8c9.824,0 17.8,7.976 17.8,17.8l0,22.25"
            pathLength="1"
          />
          <!-- t -->
          <path
            class="Aint3"
            d="M392.607,444.975l-4.45,0c-4.912,0 -8.9,-3.988 -8.9,-8.9l0,-43.75l0,8.15c0,4.912 3.988,8.9 8.9,8.9l4.45,0"
            pathLength="1"
          />
          <!-- e -->
          <path
            class="Aint4"
            d="M432.457,438.994c-3.262,3.697 -8.036,6.031 -13.35,6.031c-9.824,0 -17.8,-7.976 -17.8,-17.8c0,-9.824 7.976,-17.8 17.8,-17.8c9.824,0 17.8,7.976 17.8,17.8l-26.7,0"
            pathLength="1"
          />
          <!-- g -->
          <path
            class="Aint5"
            d="M472.6,445.025l-4.45,0c-9.824,0 -17.8,-7.976 -17.8,-17.8c0,-9.824 7.976,-17.8 17.8,-17.8c9.824,0 17.8,7.976 17.8,17.8l0,22.259c0,9.824 -7.976,17.8 -17.8,17.8l-4.45,-0.009"
            pathLength="1"
          />
          <!-- r -->
          <path
            class="Aint6"
            d="M499.3,449.475l0,-31.15c0,-4.912 3.988,-8.9 8.9,-8.9l4.45,0"
            pathLength="1"
          />
          <!-- a -->
          <path
            class="Aint7"
            d="M543.8,445.025l-4.45,0c-9.824,0 -17.8,-7.976 -17.8,-17.8c0,-9.824 7.976,-17.8 17.8,-17.8c9.824,0 17.8,7.976 17.8,17.8l0,22.25"
            pathLength="1"
          />
          <!-- t -->
          <path
            class="Aint8"
            d="M584.5,444.975l-4.45,0c-4.912,0 -8.9,-3.988 -8.9,-8.9l0,-43.75l0,8.15c0,4.912 3.988,8.9 8.9,8.9l4.45,0"
            pathLength="1"
          />
          <!-- e -->
          <path
            class="Aint9"
            d="M626.467,438.994c-3.263,3.697 -8.036,6.031 -13.35,6.031c-9.824,0 -17.8,-7.976 -17.8,-17.8c0,-9.824 7.976,-17.8 17.8,-17.8c9.824,0 17.8,7.976 17.8,17.8l-26.7,0"
            pathLength="1"
          />
          <!-- u -->
          <path
            class="Aint10"
            d="M645.481,404.975l0,22.25c0,9.824 7.976,17.8 17.8,17.8c9.824,0 17.8,-7.976 17.8,-17.8l0,-22.25"
            pathLength="1"
          />
          <!-- r -->
          <path
            class="Aint11"
            d="M695.25,449.475l0,-31.15c0,-4.912 3.988,-8.9 8.9,-8.9l4.45,0"
            pathLength="1"
          />
        </g>
      </svg>
    </div>
    <Profil />
    <Competence class="margeSup" />
    <div class="exemple">
      <h2>réalisation</h2>
      <ExempleBook
        v-aparition
        annee="2021"
        role="identiée graphique, webdesign et intégration dans prestashop 1.7"
        titre="madara"
        explication="Madara est une marque de vètement grande taille vendue exclusivement sur facebook et qui s'ouvre au e-commerce."
        photo1="./img/madara/madara_shop_1.png"
        desc1="site madara"
        photo2="./img/madara/madara_shop_2.png"
        desc2="site madara"
      />
      <ExempleBook
        v-aparition
        annee="2019"
        role="identiée graphique, webdesign et intégration html css (sass)"
        titre="colleconline"
        explication="CollecOnline est une solution digitale de gestion de collection et d'objets d'art. Le site permet aux collectionneurs d'inventorier l'ensemble des informations de leurs objets (photographies, descriptions, informations d'achat, certificat d'authenticité…)."
        photo1="./img/colleconline/colleconline_1.png"
        desc1="site colleconline.com"
        photo2="./img/colleconline/colleconline_2.png"
        desc2="site colleconline.com"
        titreLiens="visiter le site"
        liens="https://www.colleconline.com/fr"
        liensActif="true"
      />
      <div class="finsection">
        <a class="voir" :href="'book'">
          <button>
            <span>e</span>
            <span>n </span>
            <span>v</span>
            <span>o</span>
            <span>i</span>
            <span>r </span>
            <span>p</span>
            <span>l</span>
            <span>u</span>
            <span>s</span>
          </button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Profil from "@/components/Profil.vue";
import Competence from "@/components/Competence.vue";
import ExempleBook from "@/components/ExempleBook.vue";

export default {
  name: "Home",
  components: {
    Profil,
    Competence,
    ExempleBook,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
  mounted() {},
};
</script>



<style lang="scss" scoped>
.introduction {
  height: 90vh;
  display: flex;
  margin: 0 auto;
  width: 100%;
  @media screen and (max-width: 1024px) {
    height: 70vh;
  }
  #introAnime {
    position: relative;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);

    // logo intro
    #logo-MW {
      path {
        fill: rgba(252, 244, 240, 0);
        fill-rule: nonzero;
        //
        stroke: $color-logo;
        stroke-width: 1px;
        stroke-linejoin: square;
        stroke-dashoffset: 1;
        stroke-dasharray: 1;
      }
      @for $i from 1 through 3 {
        #mw#{$i} {
          animation: animMw#{$i}
              1s
              cubic-bezier(0.17, 0.67, 0.83, 0.67)
              forwards
              ($i * 0.1s),
            animMw#{$i}-2
              1s
              cubic-bezier(0.17, 0.67, 0.83, 0.67)
              forwards
              ($i * 0.4s);
        }
        @keyframes animMw#{$i} {
          to {
            stroke-dashoffset: 0;
          }
        }
        @keyframes animMw#{$i}-2 {
          to {
            fill: rgba(234, 91, 12, 1);
          }
        }
      }
    }
    // pour les textes animé
    g[id$="00"] {
      path {
        fill: none;
        stroke: rgb(234, 91, 12);
        stroke-width: 8.33px;
        stroke-linejoin: square;
        stroke-linecap: butt;
        opacity: 0;
      }
    }
    // morgan wilson
    #mo-00 {
      stroke-dashoffset: 1;
      stroke-dasharray: 1;

      @for $i from 1 through 12 {
        path:nth-child(#{$i}) {
          animation: animIn 1s ease-in-out forwards (0.2s + ($i * 0.1s));
        }
      }
      @keyframes animIn {
        from {
          stroke-dashoffset: 1;
        }
        to {
          stroke-dashoffset: 0;
        }
      }
    }
    // designer graphique
    #dg-00 {
      stroke-dashoffset: 1;
      stroke-dasharray: 1;

      @for $i from 1 through 17 {
        path:nth-child(#{$i}) {
          animation: animIn1 10s ease-in-out infinite (0s + ($i * 0.1s));
        }
        @keyframes animIn1 {
          0% {
            stroke-dashoffset: 1;
            opacity: 0;
          }
          4% {
            stroke-dashoffset: 0;
            opacity: 1;
          }
          50% {
            stroke-dashoffset: 0;
            opacity: 1;
          }
          54% {
            stroke-dashoffset: 1;
            opacity: 0;
          }
          100% {
            stroke-dashoffset: 1;
            opacity: 0;
          }
        }
      }
    }
    // intégrateur
    #int-00 {
      stroke-dashoffset: 1;
      stroke-dasharray: 1;

      @for $i from 1 through 11 {
        path:nth-child(#{$i}) {
          animation: animIn2 10s ease-in-out infinite (5s + ($i * 0.1s));
        }
        @keyframes animIn2 {
          0% {
            stroke-dashoffset: 1;
            opacity: 0;
          }
          6% {
            stroke-dashoffset: 1;
            opacity: 0;
          }
          10% {
            stroke-dashoffset: 0;
            opacity: 1;
          }
          50% {
            stroke-dashoffset: 0;
            opacity: 1;
          }
          54% {
            stroke-dashoffset: 1;
            opacity: 0;
          }
          100% {
            stroke-dashoffset: 1;
            opacity: 0;
          }
        }
      }
    }
    //
  }
}

.exemple {
  margin-top: 24rem;

  .finsection {
    width: 100%;
    //border-top: 0.1rem solid $color-logo;
    margin: 10rem auto;
    a.voir {
      text-decoration: none;
      button {
        background-color: $color-logo;
        padding: 1rem 2rem;
        border-radius: 0rem 2rem 2rem 2rem;
        border: none;
        color: white;
        display: block;
        cursor: pointer;
        margin: 0 auto;
        &::before {
          display: block;
          content: "";
          width: 50%;
          border-top: 0.3rem solid $color-logo;
          position: absolute;
          left: 0;
          transform: translateY(-1rem);
        }
        span {
          color: inherit;
          transition: all 0.2s cubic-bezier(0.89, 2, 0.8, 6);
        }
        $num: 10;
        @for $i from 1 through $num {
          span:nth-child(#{$i}) {
            transition-delay: 0.04s * $i;
          }
        }

        &:hover {
          text-shadow: 0.2rem 0.2rem 0.1rem rgba(0, 0, 0, 0.8);
        }
      }
    }
  }
}
.margeSup {
  margin-top: 40rem;
  @media screen and (max-width: $break) {
    margin-top: 20rem;
  }
}
</style>