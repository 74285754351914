import {
  createRouter,
  createWebHistory
} from 'vue-router'
import Home from '../views/Home.vue'
import Book from '../views/Book.vue'
import Contact from '../views/Contact.vue'

const routes = [{
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Book',
    name: 'book',
    component: Book
  },
  {
    path: '/Contact',
    name: 'contact',
    component: Contact
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({
          top: 0
        })
      }, 500)
    })
  },
})
export default router