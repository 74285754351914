<template>
  <div class="contPage">
    <div class="titrePage">
      <h1>
        <span>c</span>
        <span>o</span>
        <span>n</span>
        <span>t</span>
        <span>a</span>
        <span>c</span>
        <span>t</span>
      </h1>
      <!-- <div class="anime">
        <img id="ani3" class="ani" src="/animation/anim3.gif" alt="anim" />
        <img id="ani4" class="ani" src="/animation/anim2.gif" alt="anim" />
      </div> -->
      <div id="anime2"></div>
    </div>
    <p class="para">Vous souhaitez me contacter ?</p>
    <p class="para">
      Vous avez des questions, besoin de plus d'information sur moi,
      <br />
      mon parcours, mes compétences?
    </p>
    <div class="pageContact">
      <FormContact />
      <div class="carte">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d80902.1241685018!2d3.0305399926223218!3d50.679272514471485!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c32a2949a97adf%3A0xa87a5ee2bf1df6d6!2s59700%20Marcq-en-Bar%C5%93ul!5e0!3m2!1sfr!2sfr!4v1619625570223!5m2!1sfr!2sfr"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  </div>
</template>


<script>
import FormContact from "@/components/FormContact.vue";

export default {
  name: "Contact",
  components: {
    FormContact,
  },
};
</script>

<style lang="scss" scoped>
.para {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 3rem;
  line-height: 5rem;
}
.pageContact {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 12rem;
  position: relative;

  .carte {
    width: clamp(32rem, 90vw, 60rem);
    height: 50rem;
    display: inline-block;
    position: relative;
    margin: 0 1rem 5rem;
    border-radius: 20px;
    overflow: hidden;
    iframe {
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}
.titrePage {
  perspective: 100rem;

  h1 {
    span {
      display: inline-block;
      transform-style: preserve-3d;
      opacity: 0;
    }
    @for $i from 1 through 7 {
      span:nth-child(#{$i}) {
        transform: rotateY(-20deg)
          rotateX(-35deg)
          translate(random(120) - 60 + rem, random(300) - 150 + rem)
          skew(-35deg, 10deg);
      }
    }
    @for $j from 1 through 7 {
      span:nth-child(#{$j}) {
        animation: toto
          1s
          cubic-bezier(0.56, 0.05, 0.12, 1.58)
          forwards
          ($j * 0.03s);
      }
    }
    @keyframes toto {
      to {
        transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
        opacity: 1;
      }
    }
  }
  // .anime {
  //   width: 200px;
  //   height: 200px;
  //   position: absolute;
  //   bottom: 3rem;
  //   padding-left: auto;
  //   padding-right: auto;
  //   animation: suiteAnime 0.4s linear 0s 1 normal forwards;
  // }

  // .ani {
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  // }

  // #ani3 {
  //   opacity: 1;
  //   animation: debutAnime 0s linear 3.3s 1 normal forwards;
  //   z-index: 2;
  // }

  // #ani4 {
  //   opacity: 0;
  //   animation: suiteAnime 0s linear 3.3s 1 normal forwards;
  //   z-index: 1;
  // }

  // @keyframes debutAnime {
  //   from {
  //     opacity: 1;
  //   }

  //   to {
  //     opacity: 0;
  //   }
  // }

  // @keyframes suiteAnime {
  //   from {
  //     opacity: 0;
  //   }

  //   to {
  //     opacity: 1;
  //   }
  // }

  #anime2 {
    position: absolute;
    bottom: 3rem;
    padding-left: auto;
    padding-right: auto;
    width: 100px;
    height: 100px;
    display: block;
    overflow: hidden;
    background-image: url("/animation/anime-entre-02.svg"),
      url("/animation/anime-suite.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: -300px 0px;
    animation: lanime2 8s steps(157, end) 2s 1 normal both;
  }

  @keyframes lanime2 {
    from {
      /* background-position: 0px 0px, 23700px 0px; */
      background-position: 0px 0px, 7863px 0px;
    }

    to {
      /* background-position: -47100px 0px, -23400px 0px; */
      background-position: -15700px 0px, -7790px 0px;
    }
  }
}
</style>