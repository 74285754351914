const aparitionScrollObserver = new IntersectionObserver(
    (entries, aparitionScrollObserver) => {
        entries.forEach((element) => {
            if (element.isIntersecting) {
                element.target.classList.add('aparition');
                aparitionScrollObserver.unobserve(element.target);
            }
        });
    }
);







export default {
    beforeMount(el, binding, vnode) {
        el.classList.add('beforeEnter');
        aparitionScrollObserver.observe(el);
    }
}