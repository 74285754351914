<template>
  <div>
    <h2>mes compétences</h2>
    <p class="txtComp">
      Après mes études en arts graphique et design objet à St-Luc Tournai, j'ai
      élargi mon champs de compétences en suivant une formation en multimédia
      puis une autre en webdesign. Aujourd'hui, j'interviens sur divers projets
      autour du design graphique en web et print.
    </p>

    <div class="containerCards">
      <div class="subcard">
        <div class="branding card">
          <h3>branding</h3>
          <div class="contentText">
            <p>Logo, charte graphique...</p>
          </div>
          <div class="bgAnim">
            <img src="/img/BRANDING.svg" alt="picto branding" />
          </div>
        </div>
        <div class="activiteLogiciel">
          <div class="logiciel2">
            <div class="imageLogiciel2">
              <img src="/img/logiciel.svg" alt="picto - logiciel" />
            </div>
          </div>
          <div class="logiciel2">
            <div class="imageLogiciel2">
              <img src="/img/logiciel.svg" alt="picto - logiciel" />
            </div>
          </div>
          <div class="logiciel2">
            <div class="imageLogiciel2">
              <img src="/img/logiciel.svg" alt="picto - logiciel" />
            </div>
          </div>
          <div class="logiciel2">
            <div class="imageLogiciel2">
              <img src="/img/logiciel.svg" alt="picto - logiciel" />
            </div>
          </div>
        </div>
      </div>

      <div class="subcard">
        <div class="print card">
          <h3>print</h3>
          <div class="contentText">
            <p>
              Catalogues, brochures, Magasines, dépliants, flyers, faire-parts…
            </p>
          </div>
          <div class="bgAnim">
            <img src="/img/PRINT.svg" alt="picto print" />
          </div>
        </div>
        <div class="activiteLogiciel">
          <div class="logiciel2">
            <div class="imageLogiciel2">
              <img src="/img/logiciel.svg" alt="picto - logiciel" />
            </div>
          </div>
          <div class="logiciel2">
            <div class="imageLogiciel2">
              <img src="/img/logiciel.svg" alt="picto - logiciel" />
            </div>
          </div>
          <div class="logiciel2">
            <div class="imageLogiciel2">
              <img src="/img/logiciel.svg" alt="picto - logiciel" />
            </div>
          </div>
          <div class="logiciel2">
            <div class="imageLogiciel2">
              <img src="/img/logiciel.svg" alt="picto - logiciel" />
            </div>
          </div>
        </div>
      </div>

      <div class="subcard">
        <div class="web card">
          <h3>web</h3>
          <div class="contentText">
            <p>
              Création de sites internet, webdesign, UI,<br />
              intégration
            </p>
          </div>
          <div class="bgAnim">
            <img src="/img/WEB.svg" alt="picto web" />
          </div>
        </div>
        <div class="activiteLogiciel">
          <div class="logiciel2">
            <div class="imageLogiciel2">
              <img src="/img/logiciel.svg" alt="picto - logiciel" />
            </div>
          </div>
          <div class="logiciel2">
            <div class="imageLogiciel2">
              <img src="/img/logiciel.svg" alt="picto - logiciel" />
            </div>
          </div>
          <div class="logiciel2">
            <div class="imageLogiciel2">
              <img src="/img/logiciel.svg" alt="picto - logiciel" />
            </div>
          </div>
          <div class="logiciel2">
            <div class="imageLogiciel2">
              <img src="/img/logiciel.svg" alt="picto - logiciel" />
            </div>
          </div>
          <div class="logiciel2">
            <div class="imageLogiciel2">
              <img src="/img/logiciel.svg" alt="picto - logiciel" />
            </div>
          </div>
          <div class="logiciel2">
            <div class="imageLogiciel2">
              <img src="/img/logiciel.svg" alt="picto - logiciel" />
            </div>
          </div>
          <div class="logiciel2">
            <div class="imageLogiciel2">
              <img src="/img/logiciel.svg" alt="picto - logiciel" />
            </div>
          </div>
          <div class="logiciel2">
            <div class="imageLogiciel2">
              <img src="/img/logiciel.svg" alt="picto - logiciel" />
            </div>
          </div>
          <div class="logiciel2">
            <div class="imageLogiciel2">
              <img src="/img/logiciel.svg" alt="picto - logiciel" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="containerLogiciel">
      <div class="logiciel">
        <div class="imageLogiciel">
          <img src="/img/logiciel.svg" alt="picto - logiciel" />
        </div>
      </div>
      <div class="logiciel">
        <div class="imageLogiciel">
          <img src="/img/logiciel.svg" alt="picto - logiciel" />
        </div>
      </div>
      <div class="logiciel">
        <div class="imageLogiciel">
          <img src="/img/logiciel.svg" alt="picto - logiciel" />
        </div>
      </div>
      <div class="logiciel">
        <div class="imageLogiciel">
          <img src="/img/logiciel.svg" alt="picto - logiciel" />
        </div>
      </div>
      <div class="logiciel">
        <div class="imageLogiciel">
          <img src="/img/logiciel.svg" alt="picto - logiciel" />
        </div>
      </div>
      <div class="logiciel">
        <div class="imageLogiciel">
          <img src="/img/logiciel.svg" alt="picto - logiciel" />
        </div>
      </div>
      <div class="logiciel">
        <div class="imageLogiciel">
          <img src="/img/logiciel.svg" alt="picto - logiciel" />
        </div>
      </div>
      <div class="logiciel">
        <div class="imageLogiciel">
          <img src="/img/logiciel.svg" alt="picto - logiciel" />
        </div>
      </div>
      <div class="logiciel">
        <div class="imageLogiciel">
          <img src="/img/logiciel.svg" alt="picto - logiciel" />
        </div>
        <div class="versionL"><p>1.7</p></div>
      </div>
      <div class="logiciel">
        <div class="imageLogiciel">
          <img src="/img/logiciel.svg" alt="picto - logiciel" />
        </div>
        <div class="versionL"><p>vue 3</p></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Competence",
  mounted() {
    const logicielLogo = document.querySelectorAll(".logiciel");
    const subcard = document.querySelectorAll(".subcard");
    subcard.forEach((e) => {
      e.addEventListener("mousemove", (e2) => {
        //let topY = e.offsetTop;
        let introH = document.querySelector(".introduction").offsetHeight;
        //let diff = topY + introH;
        let axeX = (e2.pageX - e.offsetLeft - e.offsetWidth / 2) / 4;
        let axeY = -(e2.pageY - e.offsetTop - e.offsetHeight / 2) / 6;
        e.querySelector(
          ".card"
        ).style.transform = `rotateX(${axeY}deg) rotateY(${axeX}deg)`;
        e.querySelector(".card .bgAnim").style.transform = `translateZ(80px)`;
      });
      e.addEventListener("mouseleave", (e2) => {
        e.querySelector(
          ".card"
        ).style.transform = `rotateX(0deg) rotateY(0deg)`;
        e.querySelector(".card .bgAnim").style.transform = `translateZ(0px)`;
      });
      //
      e.addEventListener("mouseout", () => {
        logicielLogo.forEach((logi) => {
          logi.style.opacity = 0.2;
          logi.style.transform = `scale(1)`;
        });
      });
    });
    //
    const branding = document.querySelector(".subcard:nth-child(1)");
    const print = document.querySelector(".subcard:nth-child(2)");
    const web = document.querySelector(".subcard:nth-child(3)");
    const logi1 = document.querySelector(".logiciel:nth-child(1)");
    const logi2 = document.querySelector(".logiciel:nth-child(2)");
    const logi3 = document.querySelector(".logiciel:nth-child(3)");
    const logi4 = document.querySelector(".logiciel:nth-child(4)");
    const logi5 = document.querySelector(".logiciel:nth-child(5)");
    const logi6 = document.querySelector(".logiciel:nth-child(6)");
    const logi7 = document.querySelector(".logiciel:nth-child(7)");
    const logi8 = document.querySelector(".logiciel:nth-child(8)");
    const logi9 = document.querySelector(".logiciel:nth-child(9)");
    const logi10 = document.querySelector(".logiciel:nth-child(10)");
    branding.addEventListener("mouseover", () => {
      logi1.style.opacity = 1;
      logi1.style.transform = `scale(1.3)`;
      logi2.style.opacity = 1;
      logi2.style.transform = `scale(1.3)`;
      logi3.style.opacity = 1;
      logi3.style.transform = `scale(1.3)`;
      logi5.style.opacity = 1;
      logi5.style.transform = `scale(1.3)`;
    });
    print.addEventListener("mouseover", () => {
      logi1.style.opacity = 1;
      logi1.style.transform = `scale(1.3)`;
      logi2.style.opacity = 1;
      logi2.style.transform = `scale(1.3)`;
      logi3.style.opacity = 1;
      logi3.style.transform = `scale(1.3)`;
      logi5.style.opacity = 1;
      logi5.style.transform = `scale(1.3)`;
    });
    web.addEventListener("mouseover", () => {
      logi1.style.opacity = 1;
      logi2.style.opacity = 1;
      logi4.style.opacity = 1;
      logi5.style.opacity = 1;
      logi6.style.opacity = 1;
      logi7.style.opacity = 1;
      logi8.style.opacity = 1;
      logi9.style.opacity = 1;
      logi10.style.opacity = 1;
      logi1.style.transform = `scale(1.3)`;
      logi2.style.transform = `scale(1.3)`;
      logi4.style.transform = `scale(1.3)`;
      logi5.style.transform = `scale(1.3)`;
      logi6.style.transform = `scale(1.3)`;
      logi7.style.transform = `scale(1.3)`;
      logi8.style.transform = `scale(1.3)`;
      logi9.style.transform = `scale(1.3)`;
      logi10.style.transform = `scale(1.3)`;
    });
  },
};
</script>

<style lang="scss" scoped>
p.txtComp {
  margin: 0 auto 8rem;
  width: 800px;
  @media screen and (max-width: $break) {
    padding: 0 1rem;

    width: 100%;
  }
}
h2 {
  text-align: center;
  margin-top: 20rem;
}

.containerCards {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  margin: 3rem auto;
  flex-direction: row;
  flex-wrap: wrap;

  .subcard {
    perspective: 100rem;
    padding: 1rem;
    // max-width: 32rem;
    // width: 100%;
    // min-width: 20rem;
    width: clamp(20rem, 100%, 32rem);

    .card {
      transform-style: preserve-3d;
      max-width: 300px;
      width: 100%;
      height: 500px;
      background: #f6f6f6;
      padding: 2rem 0 0;
      border-radius: 20px;
      transition: all 0.1s ease-out;
      box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h3 {
        text-align: left;
        font-size: 1.5rem;
        position: absolute;
        transform: translate3d(-17rem, -1rem, 5rem) rotate(-90deg) scale(3);
      }

      .contentText {
        padding: 0 10px;
        text-align: center;

        transform: translateZ(50px);
      }

      .bgAnim {
        width: 100%;
        //position: absolute;
        //bottom: 0;
        img {
          width: 100%;
        }
      }
    }

    .activiteLogiciel {
      width: 100%;
      padding: 10px;
      //display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
      //
      display: none;
      @media screen and (max-width: $break) {
        display: flex;
      }
      .logiciel2 {
        height: 50px;
        width: 50px;
        margin: 10px;
        overflow: hidden;
        display: inline-block;
      }
    }
    //
    &:nth-child(1),
    &:nth-child(2) {
      .activiteLogiciel {
        .logiciel2:nth-child(1) {
          .imageLogiciel2 {
            transform: translateX(0px);
          }
        }
        .logiciel2:nth-child(2) {
          .imageLogiciel2 {
            transform: translateX(-50px);
          }
        }
        .logiciel2:nth-child(3) {
          .imageLogiciel2 {
            transform: translateX(-100px);
          }
        }
        .logiciel2:nth-child(4) {
          .imageLogiciel2 {
            transform: translateX(-200px);
          }
        }
      }
    }
    &:nth-child(3) {
      .activiteLogiciel {
        .logiciel2:nth-child(1) {
          .imageLogiciel2 {
            transform: translateX(0px);
          }
        }
        .logiciel2:nth-child(2) {
          .imageLogiciel2 {
            transform: translateX(-50px);
          }
        }
        .logiciel2:nth-child(3) {
          .imageLogiciel2 {
            transform: translateX(-150px);
          }
        }
        .logiciel2:nth-child(4) {
          .imageLogiciel2 {
            transform: translateX(-200px);
          }
        }
        .logiciel2:nth-child(5) {
          width: 44px;
          .imageLogiciel2 {
            transform: translateX(-250px);
          }
        }
        .logiciel2:nth-child(6) {
          width: 44px;
          .imageLogiciel2 {
            transform: translateX(-294px);
          }
        }
        .logiciel2:nth-child(7) {
          width: 44px;
          .imageLogiciel2 {
            transform: translateX(-338px);
          }
        }
        .logiciel2:nth-child(8) {
          .imageLogiciel2 {
            transform: translateX(-382px);
          }
        }
        .logiciel2:nth-child(9) {
          width: 58px;
          .imageLogiciel2 {
            transform: translateX(-432px);
          }
        }
      }
    }
  }
}
.containerLogiciel {
  max-width: 1024px;
  width: 100%;
  margin: 3rem auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  transform-style: preserve-3d;
  @media screen and (max-width: $break) {
    display: none;
  }
  .logiciel {
    height: 70px;
    width: 50px;
    margin: 1rem;
    overflow: hidden;
    display: inline-block;
    opacity: 0.2;
    transition: all 0.3s cubic-bezier(0.48, -0.4, 0.48, 1.84);

    &:nth-child(1) {
      .imageLogiciel {
        left: 0px;
      }
    }
    &:nth-child(2) {
      .imageLogiciel {
        left: -50px;
      }
    }
    &:nth-child(3) {
      .imageLogiciel {
        left: -100px;
      }
    }
    &:nth-child(4) {
      .imageLogiciel {
        left: -150px;
      }
    }
    &:nth-child(5) {
      .imageLogiciel {
        left: -200px;
      }
    }
    &:nth-child(6) {
      width: 44px;
      .imageLogiciel {
        left: -250px;
      }
    }
    &:nth-child(7) {
      width: 44px;
      .imageLogiciel {
        left: -294px;
      }
    }
    &:nth-child(8) {
      width: 44px;
      .imageLogiciel {
        left: -338px;
      }
    }
    &:nth-child(9) {
      .imageLogiciel {
        left: -382px;
      }
    }
    &:nth-child(10) {
      width: 58px;
      .imageLogiciel {
        left: -432px;
      }
    }

    .imageLogiciel {
      position: relative;
      display: block;
      width: 390px;
      height: 50px;
      white-space: nowrap;
      //
      img {
        height: 50px;
      }
    }
  }
  .versionL {
    display: block;
    width: inherit;
    height: 20px;
    color: $color-prim;
    text-align: center;
    p {
      font-size: 1rem;
    }
  }
}
</style>