<template>
  <div class="formulaire">
    <form
      id="formulaire"
      method="POST"
      action="sendForm"
      enctype="multipart/form-data"
    >
      <div>
        <label>votre nom</label>
        <input name="nom" required />
      </div>
      <div>
        <label>votre email</label>
        <input name="mail" required />
      </div>
      <div>
        <label>message</label>
        <ResizeAuto />
      </div>
      <button type="submit" value="submit" name="envoyer">envoyer</button>
    </form>
  </div>
</template>

<script>
import ResizeAuto from "@/components/ResizeAuto.vue";

export default {
  components: {
    ResizeAuto,
  },
  computed: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.textarea {
  overflow-y: hidden;
  resize: vertical;
  margin: 0.5rem;
  padding: 0.5rem;
  border-color: #edf2f7;
  background-color: #edf2f7;
  border-width: 0.25rem;
  border-radius: 0.2rem;
  max-height: 18rem;
  min-width: 15rem;
  appearance: none;
}
.formulaire {
  width: clamp(32rem, 90vw, 60rem);
  min-height: 100%;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f6f6f6;
  border-radius: 20px;
  margin: 0 1rem 5rem;
  #formulaire {
    width: 80%;
    label {
      color: $color-logo;
      display: inline-block;
      margin: 6rem 1.5rem 0rem;
      text-transform: uppercase;
      font-size: 1rem;
      letter-spacing: 0.5rem;
      font-weight: bold;
    }
    input {
      display: block;
      padding: 1rem 0.6rem;
      width: 100%;
      border: none;
      border-bottom: 1px solid $color-prim;
      background-color: rgba(255, 255, 255, 0);
    }
    button {
      background-color: $color-logo;
      padding: 1rem 2rem;
      border-radius: 2rem;
      border: none;
      color: white;
      display: block;
      margin: 5rem auto;
      cursor: pointer;
    }
  }
}
</style>