<template>
  <div class="grandParent">
    <div class="separateur">
      <span>{{ role }}</span>
      <div></div>
      <span>{{ annee }}</span>
    </div>
    <div class="parent">
      <div class="texte">
        <h3>{{ titre }}</h3>
        <p>{{ explication }}</p>
        <div v-if="liensActif">
          <a :href="liens" target="_blank">
            <button>{{ titreLiens }}</button>
          </a>
        </div>
      </div>
      <div class="cousin"></div>
      <div class="enfant">
        <div class="petitEnfant">
          <picture>
            <img :src="photo1" :alt="desc1" />
          </picture>
        </div>
        <div class="petitEnfant">
          <picture>
            <img :src="photo2" :alt="desc2" />
          </picture>
        </div>
      </div>
    </div>
    <div class="autrePhoto" v-if="galerieActif">
      <div
        @click="zoomImg"
        class="photosPlus"
        v-for="autrePhoto in autrePhotos"
        :key="autrePhoto"
      >
        <img v-bind:src="autrePhoto" :alt="desc" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExempleBookPlus",
  data() {
    return {};
  },
  props: {
    annee: String,
    role: String,
    titre: String,
    explication: String,
    photo1: String,
    photo2: String,
    desc1: String,
    desc2: String,
    titreLiens: String,
    liens: String,
    liensActif: false,
    autrePhotos: Array,
    desc: String,
    galerieActif: false,
  },
  methods: {
    zoomImg: function (e) {
      e.currentTarget.classList.toggle("photoPlusAffiche");
    },
  },
  computed: {},
  mounted() {},
  directives: {},
};
</script>

<style lang="scss" scoped>
$breakMin: 45rem;
h3 {
  font-family: comfortaa, sans-serif;
  font-weight: 400;
  text-align: left;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.grandParent {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 3rem;
  width: 100%;
  max-width: 120rem;
  margin: 0 auto;
  @media screen and (max-width: $breakMin) {
  }
  .separateur {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 10rem 0 2rem;
    padding: 0 2rem;
    flex-direction: row;

    span {
      white-space: nowrap;
      display: inline;
    }
    div {
      height: 1px;
      width: 100%;
      min-width: 20rem;
      background-color: $color-prim;
      margin: 0 1rem;
    }
    @media screen and (max-width: 52rem) {
      flex-direction: column-reverse;
      div {
        margin: 0 1rem 1rem;
      }
      span {
        white-space: initial;
      }
      span:last-child {
        font-size: 2rem;
        font-weight: bold;
      }
    }
  }
  .parent {
    width: 100%;
    min-height: 40rem;
    position: relative;
    @media screen and (max-width: $breakMin) {
      min-height: initial;
    }
    .texte {
      width: 100%;
      padding: 2em;
      position: relative;
      z-index: 2;
      text-align: left;
      h3 {
        margin-bottom: 2rem;
        font-size: 10rem;
        text-shadow: 0.1rem 0.1rem 0 rgba(255, 255, 255, 0.8);
        @media screen and (max-width: 55rem) {
          font-size: calc(3rem + 5vw);
        }
      }
      p {
        width: 50%;
        @media screen and (max-width: $breakMin) {
          width: 100%;
        }
      }
      div {
        width: 50%;
        @media screen and (max-width: $breakMin) {
          width: 100%;
        }

        a {
          text-decoration: none;
          button {
            background-color: $color-logo;
            padding: 1rem 2rem;
            border-radius: 2rem;
            border: none;
            color: white;
            display: block;
            margin: 5rem auto 0;
            cursor: pointer;
          }
        }
      }
    }
    .enfant {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      z-index: 0;
      @media screen and (max-width: $breakMin) {
        position: initial;
        top: 0;
        height: auto;
      }
    }
    .cousin {
      position: absolute;
      top: 50%;
      right: 0;
      height: 100%;
      width: 47%;
      transform: translateY(-50%);
      z-index: 5;
      // background-color: rgba(73, 174, 233, 0.2);
      &:hover + .enfant {
        z-index: 3;
      }
      &:hover + .enfant .petitEnfant {
        width: 48.5%;
      }
      &:hover + .enfant .petitEnfant:first-child {
        transform: translateY(-50%) rotate(0deg);
        left: 1%;
        img {
          box-shadow: 0px 0px 6px 6px rgba(0, 0, 0, 0.1);
        }
      }
      &:hover + .enfant .petitEnfant:last-child {
        transform: translateY(-50%) rotate(0deg);
        right: 1%;
        img {
          box-shadow: 0px 0px 6px 6px rgba(0, 0, 0, 0.1);
        }
      }
      @media screen and (max-width: $breakMin) {
        display: none;
      }
    }
    .petitEnfant {
      width: 40%;
      position: absolute;
      top: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s cubic-bezier(0.05, 0.37, 0.12, 1.61);
      picture {
        width: 100%;
        img {
          width: 100%;
        }
      }
      &:first-child {
        transform: translateY(-50%) rotate(-5deg);
        z-index: 1;
        transition-delay: 0.03s;
        left: 58%;
      }
      &:last-child {
        transform: translateY(-50%) rotate(5deg);
        z-index: -1;
        right: 2rem;
      }
      @media screen and (max-width: $breakMin) {
        position: initial;
        width: 90%;
        margin: 0 auto 1rem;
        display: none;

        &:first-child {
          transform: translate(0, 0) rotate(0deg);
          z-index: 1;
          transition-delay: 0.03s;
          left: 50%;
        }
        &:last-child {
          transform: translate(0, 0) rotate(0deg);
          z-index: -1;
          left: 50%;
        }
      }
    }
  }
  .autrePhoto {
    display: flex;
    align-content: center;
    justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 2rem auto;
    position: relative;

    .photosPlus {
      width: 25%;
      padding: 1rem;
      cursor: pointer;

      @media screen and (max-width: $breakMin) {
        width: 100%;
        padding: 0;
        margin-bottom: 1rem;
      }
      img {
        width: 100%;
      }
    }
    .photoPlusAffiche {
      width: 100%;
      height: 100%;
      // padding: 1rem;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1002;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      &::before {
        content: "";
        display: block;
        background-color: rgba(0, 0, 0, 0.6);
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
      &::after {
        content: "";
        display: block;
        position: fixed;
        height: 3rem;
        width: 3rem;
        z-index: 101;
        right: 2rem;
        top: 2rem;
        border-radius: 0.3rem;
        background-image: url("/img/burger-close.svg");
      }
    }
    @media screen and (max-width: $breakMin) {
      margin: 0rem 5%;
      justify-content: space-around;
    }
  }
}
</style>