<template>
  <div class="container">
    <div
      id="burger"
      @click="menuAfficher = !menuAfficher"
      :class="{ active: menuAfficher }"
    ></div>
    <nav
      id="nav"
      @click="menuAfficher = !menuAfficher"
      :class="{ active: menuAfficher }"
    >
      <div class="navMenu">
        <router-link to="/">
          <span class="spmenu">a</span><span class="spmenu">c</span
          ><span class="spmenu">c</span><span class="spmenu">u</span
          ><span class="spmenu">e</span><span class="spmenu">i</span
          ><span class="spmenu">l</span>
        </router-link>
        <router-link :to="{ name: 'book' }">
          <span class="spmenu">b</span><span class="spmenu">o</span
          ><span class="spmenu">o</span><span class="spmenu">k</span>
        </router-link>
        <router-link :to="{ name: 'contact' }">
          <span class="spmenu">c</span><span class="spmenu">o</span
          ><span class="spmenu">n</span><span class="spmenu">t</span
          ><span class="spmenu">a</span><span class="spmenu">c</span
          ><span class="spmenu">t</span>
        </router-link>
      </div>
      <div class="finMenu"></div>
    </nav>
    <router-view v-slot="slot">
      <transition name="pTransi" mode="out-in" :duration="1000">
        <component :is="slot.Component"></component>
      </transition>
    </router-view>
  </div>
  <div class="bgBruit"></div>
  <footer class="footer">
    <p>Design et Développement: morgan Wilson - 2021</p>
    <img src="/img/MW.svg" alt="logo mowi design" />
  </footer>
</template>

<script>
export default {
  data() {
    return {
      menuAfficher: false,
    };
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Montserrat:200,400,700|Raleway:700&display=swap");
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  #burger {
    display: block;
    width: 30px;
    height: 30px;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 200;
    cursor: pointer;
    @media screen and (max-width: 30rem) {
      background-color: $bg-color;
      border-radius: 50%;
    }
    //
    background-image: url("/img/burger-open.svg");
    &.active {
      background-image: url("/img/burger-close.svg");
    }
  }
  #nav {
    position: fixed;
    z-index: 100;
    top: 0;
    right: -100%;
    bottom: 0;
    height: 100%;
    width: 100%;
    transform: translate(0px, 0px);
    transition: all 0.5s ease-in-out;
    @media screen and (max-width: $break) {
      top: unset;
      bottom: 0;
      right: 0;
      left: 0;
      transform: translate(0px, 100%);
    }
    .navMenu {
      height: 100%;
      width: 30rem;
      position: absolute;
      right: 0;
      transform: translate(30rem, 0);
      transition: all 0.5s ease-in-out;
      z-index: 100;
      box-shadow: inset 10px 0px 20px rgba(0, 0, 0, 0.3);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #ea5b0c;
      background: radial-gradient(
        circle,
        rgba(234, 91, 12, 1) 0%,
        rgba(168, 65, 8, 1) 100%
      );
      @media screen and (max-width: $break) {
        transform: translate(0px, 100%);
        width: 100%;
      }
    }
    .finMenu {
      height: 100vh;
      width: 100%;
      position: absolute;
      z-index: 99;
      top: 0;
      left: 0;
    }
    &.active {
      transform: translate(-100%, 0px);
      @media screen and (max-width: $break) {
        transform: translate(0px, 0px);
      }
      .navMenu {
        transform: translate(0px, 0px);
      }
    }
    a {
      text-decoration: none;
      // display: block;
      color: white;
      padding: 0 3rem 0.5rem;
      border-radius: 0.5rem;
      margin: 0.5rem 0;
      //
      perspective: 1000px;
      display: flex;
      align-items: center;
      justify-content: center;
      .spmenu {
        font-family: "Montserrat", sans-serif;
        color: white;
        font-size: 5rem;
        font-weight: 200;
        font-variant: small-caps;
        transition: all 0.3s cubic-bezier(0.89, 0, 0.2, 6);
        //
        display: block;
        transition: all 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6);
        // transform: scale(1);
        transform: translate3d(0px, 0px, 0px);
      }
      &:nth-child(1) {
        $num: 7;
        @for $i from 1 through $num {
          span:nth-child(#{$i}) {
            transition-delay: 0.04s * $i;
          }
        }
      }
      &:nth-child(2) {
        $num: 4;
        @for $i from 1 through $num {
          span:nth-child(#{$i}) {
            transition-delay: 0.04s * $i;
          }
        }
      }
      &:nth-child(3) {
        $num: 7;
        @for $i from 1 through $num {
          span:nth-child(#{$i}) {
            transition-delay: 0.04s * $i;
          }
        }
      }
      &:hover span {
        // transform: scale(1.6);
        transform: translate3d(0px, 0px, 200px);
        text-shadow: 0.1rem 0.1rem 0.3rem rgba(0, 0, 0, 0.8),
          -0.1rem -0.1rem 0.3rem rgba(255, 255, 255, 1);
      }

      &.router-link-exact-active {
        background-color: #fff;
        color: #ea5b0c;
        span.spmenu {
          color: inherit;
        }
      }
    }
  }

  .bgBruit {
    position: fixed;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    width: 200%;
    height: 200vh;
    background: transparent url("/img/bruit.png") repeat 0 0;
    background-repeat: repeat;
    animation: bg-animation 0.2s infinite;
    opacity: 0.2;
    visibility: visible;
    z-index: -100;
  }
  @keyframes bg-animation {
    0% {
      transform: translate(0, 0);
    }
    10% {
      transform: translate(-5%, -5%);
    }
    20% {
      transform: translate(-10%, 5%);
    }
    30% {
      transform: translate(5%, -10%);
    }
    40% {
      transform: translate(-5%, 15%);
    }
    50% {
      transform: translate(-10%, 5%);
    }
    60% {
      transform: translate(15%, 0);
    }
    70% {
      transform: translate(0, 10%);
    }
    80% {
      transform: translate(-15%, 0);
    }
    90% {
      transform: translate(10%, 5%);
    }
    100% {
      transform: translate(5%, 0);
    }
  }
  .footer {
    width: 100%;
    height: 5rem;
    background-color: $color-prim;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5rem;
    p {
      color: white;
      font-size: 1.4rem;
      @media screen and (max-width: $break) {
        font-size: 1rem;
      }
    }
    img {
      height: 4rem;
      margin-left: 3rem;
      @media screen and (max-width: $break) {
        margin-left: 1rem;
      }
    }
  }
}

.pTransi-enter-from,
.pTransi-leave-to {
  opacity: 0;
  transition-delay: 5s;
}

.pTransi-enter-active,
.pTransi-leave-active {
  transition: all 0.5s ease-in;
}
//directives
.beforeEnter {
  opacity: 0;
  transform: translateX(10rem);
  transition: all 1s ease-in-out 0.5s;
}
.aparition {
  opacity: 1;
  transform: translateX(0rem);
}
</style>
