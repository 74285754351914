<template>
  <div class="wrapper">
    <textarea
      rows="2"
      class="textarea"
      name="message"
      required
      @input="mixin_autoResize_resize"
    ></textarea>
  </div>
</template>

<script>
import mixinAutoResize from "@/mixins/autoResize.js";

export default {
  name: "ResizeAuto",
  mixins: [mixinAutoResize],
};
</script>

<style lang="scss" scoped>
.textarea {
  overflow-y: hidden;
  resize: vertical;
  margin: 0.5rem;
  padding: 0.5rem;
  border: none;
  border-bottom: 1px solid $color-prim;
  background-color: rgba(255, 255, 255, 0);
  max-height: 18rem;
  width: 100%;
  appearance: none;
}
</style>