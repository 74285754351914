import {
    createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App);

import Aparition from './directives/aparition'
app.directive('aparition', Aparition);


app.use(store).use(router).mount("#app");

// createApp(App).use(store).use(router).mount('#app')